/**
  * @tokens Config
*/
/* Leftovers, need to find a way to get rid of these, or implement CSS vars */
/*=====     MIXINS     =====*/
.hidden {
  display: none;
}

.icon svg {
  fill: currentColor;
  max-height: 100%;
  max-width: 100%;
}

.icon use {
  fill: currentColor;
}

.v-btn__content svg {
  padding: 2px;
}

.icon-set-library {
  margin: 32px 0 64px;
  max-width: 100%;
  width: 100%;
}
.icon-set-library .icon-set-output {
  display: flex;
  flex-flow: row wrap;
}
.icon-set-library .icon-sample {
  display: block;
  flex: 1 1 auto;
  max-width: 25%;
  min-width: 33%;
  padding: 16px;
  text-align: center;
}
.icon-set-library .icon-sample .icon {
  height: 72px;
  width: auto;
}
.icon-set-library .icon-sample > div {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-flow: column nowrap;
  font-size: 12px;
  height: 100%;
  padding: 10px;
}
.icon-set-library .icon-sample > div p,
.icon-set-library .icon-sample > div span {
  flex: 0 1 auto;
}
.icon-set-library .icon-sample > div span.icon {
  margin-bottom: auto;
  margin-top: 0;
}
.icon-set-library .icon-sample > div p {
  margin-top: auto;
}
.icon-set-library svg {
  display: block;
  height: auto;
  margin: 0 auto 16px;
  max-height: 64px;
  max-width: 64px;
  width: auto;
}
.icon-set-library h4,
.icon-set-library p {
  margin: 0.5em 0;
}
.icon-set-library .group-display {
  display: flex;
  flex-flow: row nowrap;
  height: 48px;
  justify-content: space-between;
  width: 100%;
}
.icon-set-library .group-display button {
  flex: 0 1 auto;
  height: 32px;
  margin: 0 24px 0 0;
}
.icon-set-library .group-display button:last-of-type {
  margin-right: auto;
}
.icon-set-library .group-reference {
  flex: 1 0 100%;
  width: 100%;
}