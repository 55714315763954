/*=====     MIXINS     =====*/

// Usage: @include clearfix;
@mixin clearfix {
  &:before, &:after {
    clear: both;
    content: "";
    display: table;
  }
}

// Usage: @include placeholderColor(color);
@mixin placeholderColor($c) {
  ::placeholder {
    color: $c;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: $c;
  }

  ::-ms-input-placeholder {
    color: $c;
  }
}

// Usage: @include absolute-align
@mixin absolute-align {
  transform-style: preserve-3d;
}

// Usage: @include absolute-align__vertical
@mixin absolute-align__vertical {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// Usage: @include absolute-align__horizontal
@mixin absolute-align__horizontal {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

// Aspect Ratio
// @include aspect-ratio(16, 9);
@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: math.div($height, $width) * 100%;
    width: 100%;
  }
}

@mixin aspect-ratio__child {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

// transitions for fade-in or fade-out
@mixin transition-fade($property) {
  transition: $property 0.25s linear;
}

// transitions for animations
@mixin transition-movement($property) {
  transition: $property 0.2s linear;
}

// truncate text
@mixin truncate($width: 100%) {
  display: block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hidden {
  display: none;
}

@mixin ease ($ease: 0.3s) {
  @if($ease > 0.6s or $ease < 0.3s){
    @error 'Animations and easing should be between 0.3s and 0.6s';
  } @else {
    transition: all ease-in-out $ease;
    transition-timing-function: cubic-bezier(0.5, 0, 0.1, 1);
  }
}

// USAGE: to be used with vue transition tag <transition name="slide-fade">

@mixin slide-fade($duration: 0.3s, $direction: 'R-L', $distance: 300px) {
  .slide-fade-enter-active {
    @include ease(0.6s);
  }
  .slide-fade-leave-active {
    @include ease(0.6s);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    @if($direction == 'R-L') {
      transform: translateX(300px);
    }
    //  NOTE: Need to add other directions.
    opacity: 0;
  }
}

@mixin fade($duration: 0.3s) {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
