@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";#cross-site-auth {
  display: block;
  height: 1px;
  left: -1000px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: -1000px;
  user-select: none;
  width: 1px;
  z-index: 0;
}
