.icon svg {
  fill: currentColor;
  max-height: 100%;
  max-width: 100%;
}

.icon use {
  fill: currentColor;
}

.v-btn__content {
  svg {
    padding: 2px;
  }
}
