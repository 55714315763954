.icon-set-library {
  margin: 32px 0 64px;
  max-width: 100%;
  width: 100%;

  .icon-set-output {
    display: flex;
    flex-flow: row wrap;
  }

  .icon-sample {
    display: block;
    flex: 1 1 auto;
    max-width: 25%;
    min-width: 33%;
    padding: 16px;
    text-align: center;

    .icon {
      height: 72px;
      width: auto;
    }

    > div {
      background-color: #f5f5f5;
      border: 1px solid #e0e0e0;
      display: flex;
      flex-flow: column nowrap;
      font-size: 12px;
      height: 100%;
      padding: 10px;

      p,
      span {
        flex: 0 1 auto;
      }

      span.icon {
        margin-bottom: auto;
        margin-top: 0;
      }

      p {
        margin-top: auto;
      }
    }
  }

  svg {
    display: block;
    height: auto;
    margin: 0 auto 16px;
    max-height: 64px;
    max-width: 64px;
    width: auto;
  }

  h4,
  p {
    margin: 0.5em 0;
  }

  .group-display {
    display: flex;
    flex-flow: row nowrap;
    height: 48px;
    justify-content: space-between;
    width: 100%;

    button {
      flex: 0 1 auto;
      height: 32px;
      margin: 0 24px 0 0;

      &:last-of-type {
        margin-right: auto;
      }
    }
  }
  .group-reference {
    flex: 1 0 100%;
    width: 100%;
  }
}
